// <!--

if (! window.rsConf) { window.rsConf = {}; }
if (! window.rsConf.ui) { window.rsConf.ui = {}; }
window.rsConf.ui.rsbtnClass = 'rsbtn_colorskin';
window.rsConf.ui.player = [
    '<div class="b_container">',
        '<div class="rsbtn_box">',
        '<div class="rs_btn_container mobile">',
        '<div class="rs_btn">',
        '   <a href="javascript:void(0);" class="rsbtn_pause rspart rsbutton" title="Pause">',
        '       <span class="rsbtn_btnlabel">Pause</span>',
        '   </a>',
        '</div>',
        '</div>',
        '<div class="rs_btn_container stop_rs">',
        '<div class="rs_btn">',
        '   <a href="javascript:void(0);" class="rsbtn_stop rspart rsbutton" title="Stop">',
        '       <span class="rsbtn_btnlabel">Stop</span>',
        '   </a>',
        '</div>',
        '</div>',
        '<div class="rs_btn_container lang">',
        '<div class="rs_btn">',
        '   <span class="rsbtn_progress_container rspart">',
        '       <span class="rsbtn_progress_played"></span>',
        '   </span>',
        '</div>',
        '</div>',
        '<div class="rs_btn_container">',
        '<div class="rs_btn">',
        '   <a href="javascript:void(0);" class="rsbtn_volume rspart rsbutton" title="Volume">',
        '       <span class="rsbtn_btnlabel">Volume</span>',
        '   </a>',
        '   <span class="rsbtn_volume_container">',
        '       <span class="rsbtn_volume_slider"></span>',
        '   </span>',
        '</div>',
        '</div>',
        '<div class="rs_btn_container">',
        '<div class="rs_btn">',
        '   <a href="javascript:void(0);" class="rsbtn_closer" title="close">',
        '       <span class="close_rs">×</span>',
        '   </a>',
        '</div>',
        '</div>',
    '</div>'
];


window.rsConf.ui.popupbutton = [
  '<a class="rsbtn_play" accesskey="L" title="Listen with ReadSpeaker" href="">',
    '<span class="rsbtn_left rspart"><span class="rsbtn_text"><span>Listen</span></span></span>',
    '<span class="rsbtn_right rsplay rspart"></span>',
  '</a>'
];
/*
window.rsConf.ui.popupbutton = [];
window.rsConf.ui.popupplayer = [];
*/
// -->

