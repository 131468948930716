var stop_calendar_point_anim = false;
var anim_calendar_point_in_progress = false;

function calendar_view_callback() {

    if(anim_calendar_point_in_progress) {
        stop_calendar_point_anim = true;
    }

    $('.calendar_event_point_span_wrapper').detach();


    const regex = /(?:id-)(\d+).*(?:color-)(.*)$/g;

    $('.zabuto_calendar [class*="calendar_event_point"]').each(function(index, el) {

        $(el).css('position','relative').append('<div class="calendar_event_point_span_wrapper"></div>');
        var classes = $(this).attr('class').split(" ");

        for (var key in classes) {
            if (classes.hasOwnProperty(key)) {
                if(classes[key].match(/calendar_event_point/)) {

                    const str = classes[key];
                    var m;
                    var event_color = '';
                    var event_id = 0;

                    while ((m = regex.exec(str)) !== null) {

                        if (m.index === regex.lastIndex) {
                            regex.lastIndex++;
                        }

                        event_id = m[1];
                        event_color = m[2];

                    }

                    if(cal_indikation && event_color != 'multiple_indikations' && cal_indikation != event_color) {

                        // Filter event points that do not match taxonomy (cal_indikationen)

                        continue
                    }

                    if(cal_indikation && event_color == 'multiple_indikations') {

                        // Manipulate color of event points that do match taxonomy (cal_indikationen) but would display as green (multiple)

                        if(classes[key].indexOf(cal_indikation) == -1) {

                            continue
                        } else {

                            event_color = cal_indikation;
                        }

                    }

                    $(el).find('.calendar_event_point_span_wrapper').append('<span class="calendar_event_point_span '+event_color+'" data-id="'+event_id+'" data-indikation="'+event_color+'" data-origin="'+classes[key]+'"></span>');

                } else {

                    // no abv event!


                }
            }
        }


    });

    // Animate abv event points

    var ct= 1;
    var animate_calendar_points = false;

    if( animate_calendar_points) {
        $('.calendar_event_point_span_wrapper').each( function(){  // use calendar_event_point_span to animate the points themselves
            /*if (stop_calendar_point_anim && anim_calendar_point_in_progress) {
                stop_calendar_point_anim = false;
                anim_calendar_point_in_progress = false;
                return false;
            } else {*/
            anim_calendar_point_in_progress = true;
            stop_calendar_point_anim = false;
            var delay= 100 * ct;
            var obj= $(this);
            setTimeout( function(){ obj.addClass('visible'); }, delay);
            ct++;
            //}
        });

    } else {
        $('.calendar_event_point_span_wrapper').each( function(){
            $(this).addClass('visible noamin');
        });
    }




}

jQuery(document).ready(function () {
    jQuery("#my-calendar").zabuto_calendar({
        cell_border: false,
        today: (cal_date_set ? false : true),
        language: "de",
        show_days: true,
        weekstartson: 1,
        nav_icon: {
            prev: '<i class="calendar__nav--circle-left"></i>',
            next: '<i class="calendar__nav--circle-right"></i>'
        },
        action: function () {
            return setDate(this.id);
        },
        ajax: {
            url: "/wp-json/abv1/"+"inte"+"rnal/getCalendarItems/",
            modal: true
        }
    });
});