if(!query == false) {
    /*$('article p:contains('+query+')').each(function(){
      $(this).html(function(index,text){
        var entry = 'suchwort';
        var r = new RegExp(entry, 'g');
        return text.replace(r, '<span class="searched_term">'+entry+'</span>');
      });
  */
  $('.result_link').on('click', function() {
    $(this).animate({opacity: 0.4});
  })
}