


function sort_tags(indikation) {

    return false;

    var is_active ='active';
    var name = $('.tags_ul a:first').text();
    var slug = $('.tags_ul a:first').attr('data-slug');
    var link = $('.tags_ul a:first').attr('href');


    $('.tags_ul').html('<li class="news_tags news_tag_tags active"><a href="'+link+'" class="'+is_active+'">'+name+'</a></li>');

    $('.news_article section .news_tag_tags a').each(function(index, el) {

        if($(this).parents('section').find('.news_tag_indikationen a[data-filter="'+indikation+'"]').length > 0 || indikation == false) {

            name = $(this).text();
            slug = $(this).attr('data-slug');
            link = $(this).attr('href');

            if($('.tags_ul a[href="'+link+'"]').length < 1) {
                if(window.location.href.indexOf(slug) > -1) { is_active ='active'; $('.tags_ul a:first').removeClass('active'); } else { is_active = ''; }
                $('.tags_ul').append('<li class="news_tags news_tag_tags"><a href="'+link+'"  data-slug="'+slug+'" class="'+is_active+'">'+name+'</a></li>');
            }

        }

    });

}


function filter_news(indikation, is_tag) {

    if (indikation == '' || indikation == '#') {
        $('.post-type-archive-news .vc_body, .single-news .vc_body').animate({ opacity: 1 });
        //  console.log('no indikationsfilter >> Tag selected: '+news_tag);

        if (news_tag) {
            $('section').hide().addClass('rs_skip');
            
            $('section').each(function(index, el) {
                
                if ($(this).find('.news_tag_tags [data-slug="' + news_tag + '"]').length > 0) {
                    
                    $(this).fadeIn().attr('data-visible', 'true').removeClass('rs_skip');
                }
            });
        }

        sort_tags(false);
        return false;
    }

    if (!$('body').hasClass('single-news')) { $('section').hide().attr('data-visible', 'false').addClass('rs_skip'); }

    if (is_tag) {
        $('html,body').animate({ scrollTop: $('.filter_container').offset().top - 100 }, 'slow');
    }



    indikation = indikation.replace('#', '');


    //console.log('writing cookie > '+indikation);

    set_cookie('news_last_visit_' + indikation, 'now');
    //console.log(indikation);

    $('.templatera_shortcode').removeAttr('class').attr('class', 'templatera_shortcode ' + indikation);


/*    $('.news_more_indikation:not(.ced-app-teaser-news)').removeAttr('class').addClass('serviceprogramme news_more_indikation').addClass(indikation).show();
    $('.news_more_indikation:not(.ced-app-teaser-news) .replace').text(indikationen_array[indikation].name_gram);
    $('.news_more_indikation:not(.ced-app-teaser-news) .more_indikation_link').html('<strong>Zu ' + indikationen_array[indikation].name_gram + '</strong>').attr('href', $('.top_nav .indikationsmenu .' + indikation + ' a').attr('href'));*/

    $('.vc_body').addClass(indication_filter_fe);

    //$('.filter_current').html(indikationen_array[indikation].name_normal);

    var empty = true;

    $('section').each(function(index, el) {

        //$(this).fadeOut();
        if ($(this).find('.news_tag_indikationen [data-filter="' + indikation + '"]').length > 0) {
            if (news_tag) {
                //console.log(' is tag_archiv > '+news_tag);
                if ($(this).find('.news_tag_tags [data-slug="' + news_tag + '"]').length > 0) {
                    empty = false;
                    $(this).fadeIn().attr('data-visible', 'true').removeClass('rs_skip');
                }

            } else {
                empty = false;
                $(this).fadeIn().attr('data-visible', 'true').removeClass('rs_skip');
            }


        }
    });

    if (empty) {
        //window.location.href = '/news/?erkrankung=' + indikation; return false;
        window.location.href = '/news/' + indikation + '/'; return false;
    }


    setTimeout(function() {

        $('.news_link--archive').each(function(index, el) {

            var re = /(.+)(\/#.+)/;
            var str = $(this).attr('href');
            var subst = '$1';
            $(this).attr('href', str.replace(re, subst) + '#' + indikation);
            // TODO Ändern in ?erkrankungen= wenn related Posts kommen

        });

    }, 500);


    sort_tags(indikation);

    $('.reset_filter_container').css("display", "flex");
    $('.post-type-archive-news .vc_body, .single-news .vc_body').animate({ opacity: 1 });

}


// BINDING //////////////////////////////


// $('.filter a').on('click', function(event) {
//     var is_tag = false;
//     if($(this).hasClass('no-anchor')) {is_tag = true; } else {
//         $('.filter_ul').css('z-index','99999');
//         $('.filter_ul').removeClass('show_it');
//     }
// });

////////////////////////////////////////





// $(window).on('load', function() {

//     $('.more-link').each(function (index, el) {
//         var re = /(.+)(\/#.+)/;
//         var str = $(this).attr('href');
//         var subst = '$1';
//         $(this).attr('href', str.replace(re, subst));
//     });

//     if(window.location.hash && window.location.hash.match(/#erkrankung\=/)) {
//        $('.vc_row[role="main"]').addClass(window.location.hash.replace('#erkrankung\=',''));
//     }

// });




// News Tag Init */


if ($('body').attr('class').match(/post-type-archive-news|tax-news|single-news|tax-news_indikationen/)) {
    filter_news(indication_filter_fe);

    if ($('body').hasClass('single-news')) {
        //$('.back_to_news').attr('href', '/news/' + window.location.hash);
        $('html,body').animate({ scrollTop: $('.vc_body').offset().top - 50 }, 'slow'); // -$(".vc_submenu").height()},'slow');
    }
}



