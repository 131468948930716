/*if($('html').attr('class').indexOf('ie9') > 0 ) {
    console.log('is ie9');
}*/

if ($('html').hasClass('ie9') || $('html').hasClass('ie8')) { }
else if(!$('div').hasClass('hep-c-box') && !$('body').hasClass('page-id-27162') && !$('body').hasClass('page-id-27301') && !$('body').hasClass('page-id-27163') && !$('body').hasClass('page-id-27303')) {
    $('.frm_form_field input, .frm_form_field textarea').each(function(index, el) {
      initPlaceholdersOnForms();
    });
}

$('input').on('click', function() {
    $(this).parent('div').find('.frm_error').fadeOut();
})

function initPlaceholdersOnForms() {
  $('.frm_form_field input, .frm_form_field textarea').each(function(index, el) {
    var the_input = $(this);
    var the_label = $(this).parent('div').find('label');
    the_input.attr('placeholder',the_label.text().replace('        *',' *'));
    the_label.addClass('sr-only');
  });
}


$(document).on("click", ".registration-overlay .close-btn", function(event){
  event.preventDefault();
  $('.registration-overlay').css("display", "none");
});

$(document).on("click", "#frm_form_25_container .frm_button_submit, #frm_form_24_container .frm_button_submit, #frm_form_26_container .frm_button_submit, #frm_form_27_container .frm_button_submit", function(){
  if (getCookie("has_campaign")) {
    $('#field_48uyv, #field_bt8u, #field_bt8u2, #field_48uyv2').val(decodeURI(getCookie("has_campaign")));
  }
  else{
    $('#field_48uyv, #field_bt8u, #field_bt8u2, #field_48uyv2').val("-");
  }
});