var active_third_layer_link_bool = false;
var active_third_layer_link = false;
var check_scroll_end = true;
var submenu_sticky = false;
var sidebar_sticky = false;
var mobile_is_set = false; //set to false
var level = 0;
var state;
var submenu_is_stuck = false;
var pyramide_is_hidden = true;
var snapshot;
modified_theader = false;



var add_overlay_container = {
    'brightcove_video': '<div class="overlay_container iframe_video"><div class="overlay"><div tabindex="1" style="overflow:visible !important;" class="overflow"><h2 style="margin-bottom: 17px; margin-top: -40px; font-size:22px;">{{title}}</h2><div class="responsive-video {{ratio}}"><div class="videoWrapper videoWrapper__brightcove"><video id="myExperience" data-video-id="{{id}}" data-account="{{account}}" data-player="{{code}}" data-embed="default" data-application-id class="video-js" controls></video></div></div></div><script src="https://players.brightcove.net/{{account}}/{{code}}_default/index.min.js"></script><a href="#" tabindex="2" class="close_overlay remove_overlay no-anchor .internal"><span aria-hidden="true" class="close_overlay_x">×</span><span class="screen-reader">Schließen</span></a></div></div>',
    'vimeo_video': '<div class="overlay_container iframe_video"><div class="overlay"><div tabindex="1" style="overflow:visible !important;" class="overflow"><h2 style="margin-bottom: 17px; margin-top: -40px; font-size:22px;">{{title}}</h2><div class="responsive-video {{ratio}}"><iframe src="//player.vimeo.com/video/{{id}}?title=0&byline=0&portrait=0&badge=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div></div> <a href="#" tabindex="2" class="close_overlay remove_overlay no-anchor .internal"><span aria-hidden="true" class="close_overlay_x">×</span><span class="screen-reader">Schließen</span></a></div></div>',
    'rsv_video': '<div class="overlay_container rsv_movie"><div class="overlay"><div tabindex="1" style="overflow:visible !important;" class="overflow"><h2 style="margin-bottom: 17px; margin-top: -40px; font-size:22px;">RSV: Das Virus, das Eltern kennen sollten</h2> <video id="myExperience4523875019001" data-video-id="4523875019001" data-account="2157889324001" data-player="default" data-embed="default" data-application-id class="video-js" controls> </video></div> <a href="#" tabindex="2" class="close_overlay no-anchor .internal"><span aria-hidden="true" class="close_overlay_x">×</span><span class="screen-reader">Schließen</span></a></div></div>',
    'pso_overlay': '<div class="overlay_container pso_hint"> <div class="overlay"> <div tabindex="1" style="overflow:visible !important;" class="overflow"> <h2 class="pso_hint_headline" style="">Herzlich willkommen auf dem Internetportal von AbbVie Care!</h2> <p>Hier finden Sie alle Informationen, die Ihnen bislang auf www.psoriasis-gespraeche.de zur Verfügung standen und noch mehr Wissenswertes rund um die Schuppenflechte.</p><a class="pso_hint_link" href="#" data-binding="pso_hint_close">Weiter</a> </div><a href="#" tabindex="2" class="close_overlay no-anchor .internal"><span aria-hidden="true" class="close_overlay_x">×</span><span class="screen-reader">Schließen</span></a> </div></div>',
    'ai_overlay': '<div class="overlay_container ai_hint"> <div class="overlay"> <div tabindex="1" style="overflow:visible !important;" class="overflow"> <h2 class="ai_hint_headline" style="">Herzlich willkommen auf dem Internetportal von AbbVie Care!</h2> <p>Hier finden Sie alle Informationen, die Ihnen bislang auf www.acneinversa.de zur Verfügung standen und noch mehr Wissenswertes rund um die Acne inversa.</p><a class="ai_hint_link" href="#" data-binding="ai_hint_close">Weiter</a> </div><a href="#" tabindex="2" class="close_overlay no-anchor .internal"><span aria-hidden="true" class="close_overlay_x">×</span><span class="screen-reader">Schließen</span></a> </div></div>'
};




/*///////////////////////////////*/
/*///////  INIT functions  //////*/
/*///////////////////////////////*/


check_submenu_list_items();
init_op_jumps();
submenu_stick();
load_ajax_imgs();
back_to_top_arrow_stick();

$('html').removeClass('hidden');
$('body').removeClass('mobile');

css_browser_selector(navigator.userAgent);

if ($('body').hasClass('post-type-archive-news')) {
    save_news_visit();
}

if (isIE11()) {
    $('html').addClass('ie11 ie');
}

if (window.location.hash) {
    var cleaned_hash = window.location.hash.split('?')[0];
    if ($('html').hasClass('cbs_mobile')) {
        setTimeout(function () {
            load_hash(cleaned_hash.replace('#', ''));
        }, 2000);
    }
    else {
        setTimeout(function () {
            load_hash(cleaned_hash.replace('#', ''));
        }, 1000);
    }

}


// Check if TabID is given via GET Param and open it without animation
var tbid = getUrlParameter(window.location.href,'tbid');
if(tbid) {
  dlToogleTab($('.dl-container-wrapper [data-tab_id="'+ tbid + '"]'),false);
}


$('.wpb_accordion').addClass('rs_preserve');
$(window).on('hashchange', function () {
    return false;
});

if (opm) {
    // Triggering Overlays
    switch (opm) {
        case 'parkinson_therapieoptionen':
            $('.pkm').click();
            break;
        case 'wirkschwakungen':
            $('.sidebar_pkm2_teaser').click();
            break;
        case 'wirkschwankungen':
            $('.sidebar_pkm2_teaser').click();
            break;
    }
}

// 404

if (status) {
    if (status == '404') {
        $('#status_placeholder').append('<div class="status status--404"><p>Die gewünschte Seite wurde leider nicht gefunden. Haben Sie sich eventuell vertippt?</p></div>')
        $('body').addClass('show_status_message');
    }
}

// 301 which looks like a 404

if (window.location.hash.match(/404/) ) {

    var hash = window.location.hash.replace('#','');

    switch(hash) {
        case '3234-404':
            $('#status_placeholder').append('<div class="status status--404"><p>Die gewünschte News wurde leider nicht gefunden.</p></div>');
            break;
        case '9993-404':
            $('#status_placeholder').append('<div class="status status--404"><p>Die gewünschte Veranstaltung wurde leider nicht gefunden.</p></div>');
            break;
        default:
            $('#status_placeholder').append('<div class="status status--404"><p>Die gewünschte Seite wurde leider nicht gefunden.</p></div>');
    }

    $('body').addClass('show_status_message');
}

if (window.location.hash.match(/imaufbau/) ) {

    $('#status_placeholder').append('<div class="status status--aufbau"><p>Die gewünschte Seite befindet sich aktuell noch im Aufbau. Schauen Sie in Kürze wieder vorbei!</p></div>');
    $('body').addClass('show_status_message');
}


function check_for_hints() {

    if(getCookie('referer')) {
        http_referer = getCookie('referer');
    } else {
        return false;
    }

    deleteCookie('referer',true);


    if(http_referer && http_referer.match(/www.psoriasis-gespraeche.de/)) {

        if (!getCookie('pso_hint_seen')) {

            $('.add_overlay_container').append(add_overlay_container.pso_overlay);
            $('.overlay_container.pso_hint').addClass('opened');
            $('.overlay_container.pso_hint').fadeIn();
            $('[data-binding="pso_hint_close"]').attr('tabindex', '1');
            $('.overlay_container.pso_hint .overlay').addClass('opened');
            mask_focus('.overlay_container.pso_hint');

        }

        $(document).on('click', '.pso_hint .close_overlay, [data-binding="pso_hint_close"]', function () {
            setCookie('pso_hint_seen', 'true', true);
        });


    }



    if(http_referer && http_referer.match(/www.acneinversa.de/)) {

        if (!getCookie('ai_hint_seen')) {

            $('.add_overlay_container').append(add_overlay_container.ai_overlay);
            $('.overlay_container.ai_hint').addClass('opened');
            $('.overlay_container.ai_hint').fadeIn();
            $('[data-binding="ai_hint_close"]').attr('tabindex', '1');
            $('.overlay_container.ai_hint .overlay').addClass('opened');
            mask_focus('.overlay_container.ai_hint');

        }

        $(document).on('click', '.ai_hint .close_overlay, [data-binding="ai_hint_close"]', function () {
            setCookie('ai_hint_seen', 'true', true);
        });


    }

}


// Filter Search Result Count

if(typeof filtered_search_result_count !== typeof undefined) $('p.filtered_search_result .filtered_search_result_content').html(filtered_search_result_count);
$('p.filtered_search_result').fadeIn();

$('.nav-container .masked *').attr('tabindex','-1');

if ($('aside nav').length < 1) {
    $('[data-jump="sections"]').hide();
}

if (parseInt($('.submenu_ul li.current_page_item').index() + 1) == parseInt($('.submenu_ul li').length)) {
    $('[data-jump="related"]').hide();
}

/***Color blind helpers **/

if (display_contrast) {

    $('a').each(function (index, el) {
        $(this).attr('href', $(this).attr('href') + '?contrast');
    });
    $('h1, h2, h3, h4, h5, h6, a, p, span').each(function (index, el) {
        $(this).append('<span style="  display: block; font-size: 9px; color: #000;">(Size: ' + $(this).css('font-size') + ', Color: ' + $(this).css('color') + ')</span>');
    });
}



/*///////////////////////////////*/
/*///////  onLoad functions  //////*/
/*///////////////////////////////*/

$(window).on('load', function () {



    check_for_hints();
    
    $('.preloader_container').fadeOut();
    $(document.body).trigger("sticky_kit:recalc");
    $('.divider:eq(0)').addClass('first');

    check_width();
    $('.top_nav').fadeIn();

    if(cal_indikation) {
        $('.reset_filter_container').css("display", "flex");
        set_cookie('events_last_visit_' + cal_indikation, 'now');
    }
    if(news_tag) {
        $('.reset_filter_container').css("display", "flex");
    }

    read_cookie();


});
